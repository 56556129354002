import React, { useState } from "react";
import classNames from "classnames";
import "./header.sass";
import { Link } from "react-router-dom";
import logo from "../images/logo.svg";
import menu from "../images/header/menu.svg";
import close from "../images/header/close.svg";

const menu_list = [
  { text: "Home", link: "/" },
  { text: "Team", link: "/team" },
  { text: "Fi-Mi Architecture", link: "/architecture" },
  { text: "Objectives", link: "/objective" },
  { text: "Data Analysis", link: "http://demo.fi-mi.vn/app", external: true }, 
  { text: "Publications", link: "/publication" },
  { text: "News", link: "/news" },
  { text: "Contact", link: "/contact" },
  { text: "WORKSHOP 2021", link: "/events/1", color: "#5e99f7" }
];

const Header = () => {
  const [open, setOpen] = useState(false);

  const showMenu = () => setOpen(true);

  const closeMenu = (e) => {
    const tag = e.target.tagName;

    if (tag !== "A" && tag !== "IMG") {
      return;
    }

    setOpen(false);
  };

  return (
    <div className="header">
      <div className="navigation">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
        <div className="menu">
          {menu_list.map((item, index) => {
            if (item.color) {
              return (
            <Link key={item.link} to={item.link} className="item" style={{color: item.color}}>
              {item.text}
            </Link>)
            }
            else 
            {
            if (item.external) {
              return (
                <a key={item.link} href={item.link} className="item">
                  {item.text}
                </a>
            //   <Route className="item" path='/privacy-policy' component={() => { 
            //     window.location.href = item.link; 
            //     return null;
            // }}/>
              );
            }
            else
            return (
              <Link key={item.link} to={item.link} className="item">
                {item.text}
              </Link>)
            }
          })          
          }
        </div>
      </div>
      <div className="mobile_navigation">
        <div className="menu_icon" onClick={() => showMenu()}>
          <img src={menu} alt="menu" />
        </div>
        <div
          className={classNames({ menu_list: true, active: open })}
          onClick={(e) => closeMenu(e)}
        >
          <div className="menu_close">
            <img src={close} alt="close" />
          </div>
          {menu_list.map((item, index) => {
            if (item.color) {
              return (
            <Link key={item.link} to={item.link} className="item" style={{color: item.color}}>
              {item.text}
            </Link>)
            }
            else 
            {
            if (item.external) {
              return (
                <a key={item.link} href={item.link} className="item">
                  {item.text}
                </a>
              );
            }
            else
            return (
              <Link key={item.link} to={item.link} className="item">
                {item.text}
              </Link>)
            }
          })}
        </div>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
