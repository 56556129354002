const news_detail_list = [
    {
        id: 8,
        title: "Fi-Mi 1st Workshop", 
        image: [
          {
            url: require("../../images/body/news/news_fimi.png"),
            caption: 'Fi-Mi 1st Workshop'
          }
        ],
        time: "11th Dec 2020",
        description: "Dr. Phi-Le Nguyen signed the sponsorship agreement with VINIF. The Fi-Mi project officially started.",
        first_pline: "Welcome to the first Workshop by the Fi-Mi team. The theme of the Workshop is \“Technologies for Air Quality Monitoring and Prediction\". The Workshop summarizes the main activities and results of the Fi-Mi team in one year. It also provides a large picture of research and development on Air Quality Monitoring and Prediction through several invited speeches. Participants may identify state-of-the-art technologies in the field, exchange scientific knowledge, and figure out possible future collaborations. The Workshop will be held in Vietnamese.",
        content: [
            {
                header: "Agenda",
                description: 
                `
                <b class="news_detail_content_subsection" > SECTION 1: INVITED SPEECHES</b>
                <ul> 
                    <li> 10:00-10:10 - Opening Speech </li>
                    <li> 10:10-10:50 - Prof. Nguyen Thi Nhat Thanh, Air pollution monitoring in Vietnam, challenges and opportunities" (Giám sát môi trường không khí ở Việt Nam, những thách thức và cơ hội) </li>
                    <li> 10:50-11:30 - Dr.Nguyen Diep and Dr. Nguyen Van Huynh, "AI-empowered Edge Computing with efficient and secure Distributed Learning" (Điện toán biên được hỗ trợ bởi AI với khả năng học tập phân tán hiệu quả và an toàn) </li>
                    <li> 11:30-11:40 - Break</li>
                    <li> 11:40-12:20 - Dr. Nguyen Phi Le, "Fi-mi report: Main activities and achievements in the year 2021" (Báo cáo hoạt động và kết quả năm 2021 của dự án Fi-mi) </li>
                    <li> 12:20-13:30 - Lunch break</li>
                </ul>
                <b class="news_detail_content_subsection" > SECTION 2: Deployment and Localization </b>
                <ul> 
                    <li> 13:30-14:00 - Prof. Huynh Thi Thanh Binh, "Minimum relay node ensuring connectivity in mobile wireless sensor networks" (Tối thiểu hóa nút chuyển tiếp để đảm bảo kết nối trong mạng cảm biến) </li>
                    <li> 14:00-14:30 - Dr. Nguyen Cam Ly, "Overview and state-of-the-art techniques on mobile wireless localization" (Tổng quan và các kỹ thuật hiện đại về định vị không dây)  </li>
                    <li> 14:30-15:00 - Bùi Xuân Phước, "Optimal deployment for Opportunistic sensing in mobile air-quality monitoring system" (Tối ưu triển khai cảm biến cơ hội trong hệ thống quan trắc chất lượng không khí di động) </li>
                    <li> 15:00-15:10 - Break</li>
                </ul>  
                <b class="news_detail_content_subsection" > SECTION 3: Devices and Predictions </b>
                <ul> 
                    <li> 15:10-15:40 - Prof. Nguyen Kien, "On-device network softwarization technologies for efficient resource utilization" (Các công nghệ ảo hóa cho việc sử dụng hiệu quả tài nguyên mạng trên thiết bị di động)  </li>
                    <li> 15:40-16:10 - Nguyen Minh Hieu,  "PM2.5 prediction using Genetic Algorithm-based feature selection and Encoder-Decoder model" (Dự đoán chỉ số PM2.5 sử dụng mô hình Encoder-Decoder và giải thuật di truyền) </li>
                    <li> 16:10-16:40 - Nguyen Thanh Hung,  "Mobile air-quality monitoring device" (Thiết kế thiết bị đo thông số môi trường di động)  </li>
                    <li> 16:40-16:50 - Break </li>
                </ul>
                <b class="news_detail_content_subsection" > SECTION 4: Edge computing and Energy harvesting	 </b>
                <ul> 
                    <li> 16:50-17:20 - Do Bao Son, "Learning-based algorithm for offloading vehicular edge computing" Học tăng cường cho bài toán giảm tải công việc trong điện toán biên di động </li>
                    <li> 17:20-17:50 - Dinh Quang Minh, "Ambient RF energy harvesting for autonomous IoT device" (Khai thác năng lượng từ sóng RF cho các thiết bị IoT tự chủ năng lượng) </li>
                    <li> 17:50-18:00 - Closing remarks </li>
                </ul>
                `
            },
            {
                header: "Venue",
                description: "Date: December 11, 2021 <br/> Place: Join Zoom Meeting <br/> Zoom Meeting Link: <a href=\"https://us02web.zoom.us/j/84356656735?pwd=OS9QS2MwTC8rK1Y2NjkvUGlveUZKZz09\">Link</a> <br/> <br/> Meeting ID: 843 5665 6735 <br/> Passcode: 894790"
            }
        ]
    }
]

export default news_detail_list;
