const member_list = [
    {
      title: "Professors",
      member: [
        {
          member_name: "Do Phan Thuan",
          personal_link: "https://scholar.google.com/citations?user=7Bpp8U0AAAAJ&hl=en&oi=ao",
          member_image: require("../../images/body/team/member/Do_Phan_Thuan.jpg"),
          modal: {
            title: "Mr. Do Phan Thuan",
            profile: "Lecturer at Hanoi University of Science and Technology, Hanoi, Vietnam",
            contact: {
                email: "thuandp@soict.hust.edu.vn"
            }
          }
        },
        {
          member_name: "Huynh Thi Thanh Binh",
          member_image: require("../../images/body/team/member/Huynh_Thi_Thanh_Binh.jpg"),
          personal_link: "https://users.soict.hust.edu.vn/binhht/",
          modal: {
            title: "Mrs. Huynh Thi Thanh Binh",
            profile: "Lecturer at Hanoi University of Science and Technology, Hanoi, Vietnam",
            contact: {
                email: "binhht@soict.hust.edu.vn"
            } 
          }
        },
        {
          member_name: "Nguyen Phi Le",
          member_image: require("../../images/body/team/member/Nguyen_Phi_Le.jpg"),
          personal_link: "https://users.soict.hust.edu.vn/lenp/",
          modal: {
            title: "Mrs. Nguyen Phi Le",
            profile: "Lecturer at Hanoi University of Science and Technology, Hanoi, Vietnam",
            contact: {
                email: "lenp@soict.hust.edu.vn"
            } 
          }
        },
        {
          member_name: "Nguyen Cam Ly",
          member_image: require("../../images/body/team/member/Nguyen_Cam_Ly.jpg"),
          personal_link: "https://www.researchgate.net/profile/Cam_Ly_Nguyen",
          modal: {
            title: "Mrs. Nguyen Cam Ly",
            profile: "Working in Research and Development Center, Network System Laboratory at Toshiba Corporation, Tokyo, Japan",
            contact: {
                email: "camly268@gmail.com"
            } 
          }
        },
        {
          member_name: "Le Minh Thuy",
          member_image: require("../../images/body/team/member/Le_Minh_Thuy.jpg"),
          personal_link: "https://scholar.google.com/citations?user=ntVxHCwAAAAJ&hl=en&oi=ao",
          modal: {
            title: "Mrs. Le Minh Thuy",
            profile: "Lecturer at Hanoi University of Science and Technology, Hanoi, Vietnam",
            contact: {
                email: "thuy.leminh@hust.edu.vn"
            } 
          }
        },
        {
          member_name: "Nguyen Kien",
          member_image: require("../../images/body/team/member/Nguyen_Kien.png"),
          personal_link: "https://www.s-lab.nd.chiba-u.jp/nguyen/index.html",
          modal: {
            title: "Mr. Nguyen Kien",
            profile: "Lecturer at Chiba University, Chiba, Japan",
            contact: {
                email: "nguyen@chiba-u.jp"
            } 
          }
        }
      ]
    },
    {
      title: "Research Assistants",
      member: [
        {
          member_name: "Nguyen Anh Duy",
          // personal_link: "https://scholar.google.com/citations?user=7Bpp8U0AAAAJ&hl=en&oi=ao",
          member_image: require("../../images/body/team/member/duy.jpeg"),
          modal: {
            title: "Mr. Nguyen Anh Duy",
            profile: "Undergraduate student at Hanoi University of Science and Technology, Hanoi, Vietnam",
            contact: {
                email: "duy.na184249@sis.hust.edu.vn"
            }
          }
        },
        {
          member_name: "Vu Viet Hung",
          // personal_link: "https://scholar.google.com/citations?user=7Bpp8U0AAAAJ&hl=en&oi=ao",
          member_image: require("../../images/body/team/member/hung.jpeg"),
          modal: {
            title: "Mr. Vu Viet Hung",
            profile: "Master student at Hanoi University of Science and Technology, Hanoi, Vietnam",
            contact: {
                email: "vuviethung.98.forwork@gmail.com"
            }
          }
        },
        {
          member_name: "Nguyen Thuy Dung",
          // personal_link: "https://scholar.google.com/citations?user=7Bpp8U0AAAAJ&hl=en&oi=ao",
          member_image: require("../../images/body/team/member/dung.jpeg"),
          modal: {
            title: "Ms. Nguyen Thuy Dung",
            profile: "Undergraduate student at Hanoi University of Science and Technology, Hanoi, Vietnam",
            contact: {
                email: "dung.nt184247@sis.hust.edu.vn"
            }
          }
        },
        {
          member_name: "Nguyen Dong Duc Anh",
          // personal_link: "https://scholar.google.com/citations?user=7Bpp8U0AAAAJ&hl=en&oi=ao",
          member_image: require("../../images/body/team/member/ducanh.jpg"),
          modal: {
            title: "Mr. Nguyen Dong Duc Anh",
            profile: "Undergraduate student at Hanoi University of Science and Technology, Hanoi, Vietnam",
            contact: {
                email: ""
            }
          }
        },
        {
          member_name: "Nguyen Minh Hieu",
          // personal_link: "https://scholar.google.com/citations?user=7Bpp8U0AAAAJ&hl=en&oi=ao",
          member_image: require("../../images/body/team/member/hieu.jpeg"),
          modal: {
            title: "Mr. Nguyen Minh Hieu",
            profile: "Master graduate at Hanoi University of Science and Technology, Hanoi, Vietnam",
            contact: {
                email: ""
            }
          }
        },
        {
          member_name: "Nguyen Dac Thai",
          // personal_link: "https://scholar.google.com/citations?user=7Bpp8U0AAAAJ&hl=en&oi=ao",
          member_image: require("../../images/body/team/member/thai.jpeg"),
          modal: {
            title: "Mr. Nguyen Dac Thai",
            profile: "Undergraduate student at Hanoi University of Science and Technology, Hanoi, Vietnam",
            contact: {
                email: ""
            }
          }
        },
        {
          member_name: "Do Manh Quan",
          // personal_link: "https://scholar.google.com/citations?user=7Bpp8U0AAAAJ&hl=en&oi=ao",
          member_image: require("../../images/body/team/member/quan.jpeg"),
          modal: {
            title: "Mr. Do Manh Quan",
            profile: "Undergraduate student at Hanoi University of Science and Technology, Hanoi, Vietnam",
            contact: {
                email: ""
            }
          }
        },
        {
          member_name: "Le Xuan Vinh",
          // personal_link: "https://scholar.google.com/citations?user=7Bpp8U0AAAAJ&hl=en&oi=ao",
          member_image: require("../../images/body/team/member/vinh.jpeg"),
          modal: {
            title: "Mr. Le Xuan Vinh",
            profile: "Undergraduate student at Hanoi University of Science and Technology, Hanoi, Vietnam",
            contact: {
                email: ""
            }
          }
        },
        {
          member_name: "Manh Xuan Chinh",
          // personal_link: "https://scholar.google.com/citations?user=7Bpp8U0AAAAJ&hl=en&oi=ao",
          member_image: require("../../images/body/team/member/chinh.jpeg"),
          modal: {
            title: "Mr. Manh Xuan Chinh",
            profile: "Undergraduate student at Hanoi University of Science and Technology, Hanoi, Vietnam",
            contact: {
                email: ""
            }
          }
        },
        {
          member_name: "Nguyen Manh",
          // personal_link: "https://scholar.google.com/citations?user=7Bpp8U0AAAAJ&hl=en&oi=ao",
          member_image: require("../../images/body/team/member/manh.jpeg"),
          modal: {
            title: "Mr. Nguyen Manh",
            profile: "Undergraduate student at Hanoi University of Science and Technology, Hanoi, Vietnam",
            contact: {
                email: ""
            }
          }
        },
      ]
    }
  ];
  
  export default member_list;
  