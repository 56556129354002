import React, {useEffect} from "react";
import { Link } from 'react-router-dom'
import "./events.sass";
import events_list from "./data.js";
import { Markup } from "interweave";

const Event = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let item = events_list.find(item => item.id === parseInt(window.location.pathname.split('/')[2]));

  return (
    <>
      <div 
        className="block_title"
        style={{
          backgroundImage: `url("${require("../../images/header/news.png")}"`
        }}
      >{item.header}</div>
      <div className="news_detail">
        {/* <div className="news_detail_image">
          <img src={require("../../images/body/news/news_fimi.png")} alt="" />
        </div> */}
        <div className="news_detail_content">
          <div className="news_detail_content_title">{item.title}</div>
          <div className="news_detail_content_time">{item.time}</div>
          <p className="first_pline">
            {item.first_pline}
          </p>
          <br/>
          {
            item.content.map((subitem, index) => (
              <>
                <p>
                  <b class="news_detail_content_section">{subitem.header}</b>
                </p>
                <Markup content={subitem.description} />
              </>
            ))
          }
        </div>
      </div>
    </>
  );
};

export default Event;
