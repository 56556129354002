const events_list = [
    {
        id: 1,
        header: "WORKSHOP 2021",
        title: "Fi-Mi 1st Workshop", 
        image: [
          {
            url: require("../../images/body/news/news_fimi.png"),
            caption: 'Fi-Mi 1st Workshop'
          }
        ],
        time: "11th Dec 2021",
        description: "Dr. Phi-Le Nguyen signed the sponsorship agreement with VINIF. The Fi-Mi project officially started.",
        first_pline: "Welcome to the first Workshop by the Fi-Mi team. The theme of the Workshop is \“Technologies for Air Quality Monitoring and Prediction\". The Workshop summarizes Fi-Mi team's main activities and achivements for the year 2021. It also provides a large picture of research and development on Air Quality Monitoring and Prediction through several invited speeches. Participants may identify state-of-the-art technologies in the field, exchange scientific knowledge, and figure out possible future collaborations. The Workshop will be held in Vietnamese.",
        content: [
            {
                header: "Venue",
                description: "<p className=\"first_pline\"> Date: December 11, 2021 <br/> Participation guideline: <a href=\"https://docs.google.com/document/d/10lEIUPj7hiydX6JuPqJ5LN585k6xmPfAWqCzc938-UA/edit?fbclid=IwAR0oM7fRwp5rEZGKP-XqwiC0cp8bIN8iXRDFvg8z-av99vskklsKnTRjOn8\">Link</a> <br/> <br/> Place: Join Zoom Meeting <br/> <span style=\"color: red; font-weight: bold \"}> Zoom Meeting Link: </span> <a href=\"https://us02web.zoom.us/j/84356656735?pwd=OS9QS2MwTC8rK1Y2NjkvUGlveUZKZz09\">Link</a> <br/>  Meeting ID: 843 5665 6735 <br/> Passcode: 894790 </p>"
            },
            {
                header: "Agenda",
                description: 
                `
                <b class="news_detail_content_subsection" > SESSION 1: Invited Speeches</b>
                <ul> 
                    <li><p className="first_pline"> 10:00-10:10 - Opening Speech </p></li>
                    <li><p className="first_pline"> 10:10-10:45 - Prof. Nguyen Thi Nhat Thanh, Vietnam National University, "Air pollution monitoring in Vietnam, challenges and opportunities" (Giám sát môi trường không khí ở Việt Nam, những thách thức và cơ hội) </p></li>
                    <li><p className="first_pline"> 10:45-11:20 - Dr.Nguyen Diep and Dr. Dinh Thai Hoang (University of Technology Sydney, Australia) and Dr. Nguyen Van Huynh (Imperial College London), "AI-empowered Edge Computing with efficient and secure Distributed Learning" (Điện toán biên được hỗ trợ bởi AI với khả năng học tập phân tán hiệu quả và an toàn) </p></li>
                    <li><p className="first_pline"> 11:20-11:30 - Break</li>
                    <li><p className="first_pline"> 11:30-12:10 - Dr. Nguyen Phi Le, Hanoi University of Science and Technology 
                    "Fi-mi report: Main activities and achievements in the year 2021" (Báo cáo hoạt động và kết quả năm 2021 của dự án Fi-mi)" </p></li>
                    <li><p className="first_pline"> 12:10-13:30 - Lunch break</li>
                </ul>
                <b class="news_detail_content_subsection" > SESSION 2: Deployment and Localization </b>
                <ul> 
                    <li><p className="first_pline"> 13:30-14:00 - Prof. Huynh Thi Thanh Binh, Hanoi University of Science and Technology , "Minimum relay node ensuring connectivity in mobile wireless sensor networks" (Tối thiểu hóa nút chuyển tiếp để đảm bảo kết nối trong mạng cảm biến) </p></li>
                    <li><p className="first_pline"> 14:00-14:30 - Dr. Nguyen Cam Ly, Criteo Japan, "Overview and state-of-the-art techniques on wireless localization" (Tổng quan và các kỹ thuật hiện đại về định vị không dây)  </p></li>
                    <li><p className="first_pline"> 14:30-15:00 - Bùi Xuân Phước, VGU-Vietnam German University
                    "Optimal deployment for Opportunistic sensing in mobile air-quality monitoring system" (Tối ưu triển khai cảm biến cơ hội trong hệ thống quan trắc chất lượng không khí di động)" </p></li>
                    <li><p className="first_pline"> 15:00-15:10 - Break</li>
                </ul>  
                <b class="news_detail_content_subsection" > SESSION 3: Devices and Predictions </b>
                <ul> 
                    <li> <p className="first_pline"> 15:10-15:40 - Prof. Nguyen Kien, Chiba University, "On-device network softwarization technologies for efficient resource utilization" (Các công nghệ ảo hóa cho việc sử dụng hiệu quả tài nguyên mạng trên thiết bị di động)  </p></li>
                    <li> <p className="first_pline"> 15:40-16:10 - Nguyen Minh Hieu, Hanoi University of Science and Technology 
                    "PM2.5 prediction using Genetic Algorithm-based feature selection and Encoder-Decoder model" (Dự đoán chỉ số PM2.5 sử dụng mô hình Encoder-Decoder và giải thuật di truyền)" </p></li>
                    <li> <p className="first_pline"> 16:10-16:40 - Nguyen Thanh Hung, Hanoi University of Science and Technology, "Mobile air-quality monitoring device" (Thiết kế thiết bị đo thông số môi trường di động)  </p></li>
                    <li> <p className="first_pline"> 16:40-16:50 - Break </li>
                </ul>
                <b class="news_detail_content_subsection" > SESSION 4: Edge computing and Energy harvesting	 </b>
                <ul> 
                    <li> <p className="first_pline"> 16:50-17:20 - Do Bao Son, Hanoi University of Science and Technology, "Learning-based algorithm for offloading vehicular edge computing" (Học tăng cường cho bài toán giảm tải công việc trong điện toán biên di động) </p></li>
                    <li> <p className="first_pline"> 17:20-17:50 - Dinh Quang Minh, Hanoi University of Science and Technology, "Ambient RF energy harvesting for autonomous IoT device" (Khai thác năng lượng từ sóng RF cho các thiết bị IoT tự chủ năng lượng) </p></li>
                    <li> <p className="first_pline"> 17:50-18:00 - Closing remarks </p></li>
                </ul>
                `
            }
        ]
    }
]

export default events_list;
