const news_list = [
    {
        id: 1,
        title: "PROJECT START-UP", 
        image: [
          {
            url: require("../../images/body/news/news_sign.jpeg"),
            caption: 'Slide 1'
          },
          {
            url: require("../../images/body/news/news_sign2.jpeg"),
            caption: 'Slide 2'
          },
        ],
        time: "12th Oct 2020",
        description: "Dr. Phi-Le Nguyen signed the sponsorship agreement with VINIF. The Fi-Mi project officially started.",
        view_detail: false
    },
    {
        id: 2,
        title: "Viet-Dung Nguyen et al. got a paper accepted in IEEE NCA 2020. Congratulations!", 
        image: [
          {
            url: require("../../images/body/news/news_ieee.png"),
            caption: 'IEEE NCA 2020'
          }
         ],
        time: "23th Oct 2020",
    },
    {
        id: 3,
        title: "Van Quan La et al. got a paper accepted in IEEE NCA 2020. Congratulations!", 
        image: [ 
          {
            url: require("../../images/body/news/news_ieee.png"),
            caption: 'IEEE NCA 2020'
          }
        ],
        time: "23th Oct 2020",
    },
    {
        id: 4,
        title: "Minh Hieu Nguyen et al. got a paper accepted in IEEE Access. Congratulations!", 
        image: [ 
          {
            url: require("../../images/body/news/news_ieee_access.png"),
            caption: 'IEEE Access'
          }
         ],
        time: "5th Apr 2021",
    },
    {
      id: 5,
      title: "Kien Nguyen, Phi Le Nguyen, et al. got a paper accepted in  IEEE Transactions on Network and Service Management. Congratulations!", 
      image: [ 
        {
          url: require("../../images/body/news/news_ieee_trans.png"),
          caption: 'IEEE Transactions on Network and Service Management'
        }
       ],
      time: "1st July 2021",
  },
  {
    id: 6,
    title: "Viet An Nguyen, Viet Hung Vu, Van Sang Doan, Thanh Hung Nguyen, et al. got a paper accepted in IEEE APCC 2021. Congratulations!", 
    image: [ 
      {
        url: require("../../images/body/news/news_ieee.png"),
        caption: 'IEEE APCC 2021'
      }
     ],
    time: "22th Aug 2021",
  },
  {
    id: 7,
    title: "Thuy Dung Nguyen, Tuyen Nguyen, et al. got a paper accepted in IEEE Transactions on Green Communications and Networking. Congratulations!", 
    image: [ 
      {
        url: require("../../images/body/news/news_ieee_trans2.png"),
        caption: 'IEEE Transactions on Green Communications and Networking'
      }
    ],
    time: "11th Oct 2021",
  },
  {
    id: 8,
    title: "Fi-Mi 1st Workshop: Technologies for Air Quality Monitoring and Prediction", 
    image: [ 
      {
        url: require("../../images/body/news/news_fimi.png"),
        caption: 'Fi-Mi 1st Workshop'
      }
    ],
    time: "11th Dec 2021",
    view_detail: true
  },
  {
    id: 9,
    title: "Minh Quang Dinh et al. got a paper accepted in IEEE Access. Congratulations!", 
    image: [ 
      {
        url: require("../../images/body/news/news_ieee_access.png"),
        caption: 'IEEE Access'
      }
    ],
    time: "8th March 2022",
  },
  {
    id: 10,
    title: "La Van Quan, Minh Hieu Nguyen, et al. got a paper accepted in ACM Transactions on Sensor Networks (TOSN). Congratulations!", 
    image: [ 
      {
        url: require("../../images/body/news/news_tson.png"),
        caption: 'TOSN'
      }
    ],
    time: "4th Jan 2022",
  },
  {
    id: 11,
    title: "Xuan Chen et al. got a paper accepted in IEEE Internet of Things (IoT) Journal. Congratulations!", 
    image: [ 
      {
        url: require("../../images/body/news/news_ieee_iot.png"),
        caption: 'IEEE IoT'
      }
    ],
    time: "2nd Apr 2022",
  },
  {
    id: 12,
    title: "Hiroaki Motohashi, Phi Le Nguyen, et al. got a paper accepted in IEEE Access. Congratulations!", 
    image: [ 
      {
        url: require("../../images/body/news/news_ieee_access.png"),
        caption: 'IEEE Access'
      }
    ],
    time: "17th July 2022",
  } 
  ];

export default news_list;