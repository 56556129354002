import React, {useEffect} from "react";
import { Link } from 'react-router-dom'
import "./newsdetails.sass";
import new_detail_list from "./data.js";
import { Markup } from "interweave";

const NewsDetail = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let item = new_detail_list.find(item => item.id === parseInt(window.location.pathname.split('/')[2]));

  return (
    <>
      <div 
        className="block_title"
        style={{
          backgroundImage: `url("${require("../../images/header/news.png")}"`
        }}
      >News</div>
      <div className="news_detail">
        <div className="news_detail_header">
          <Link to="/news">News</Link>
          <span> / {item.title} </span>
        </div>
        <div className="news_detail_image">
          <img src={require("../../images/body/news/news_fimi.png")} alt="" />
        </div>
        <div className="news_detail_content">
          <div className="news_detail_content_title">{item.title}</div>
          <div className="news_detail_content_time">{item.time}</div>
          <p className="first_pline">
            {item.first_pline}
          </p>
          {/* <p className="link_file">
            <a href="#abc">5G-Enhance_newsletter_No1</a>
          </p> */}
          {
            item.content.map((subitem, index) => (
              <>
                <p>
                  <b class="news_detail_content_section">{subitem.header}</b>
                </p>
                <Markup content={subitem.description} />
              </>
            ))
          }
        </div>
        {/* <div className="news_detail_related">
          <div className="news_detail_related_label">Related News</div>
          <div className="news_detail_related_list">
            <div className="news_detail_related_list_item">
              <div className="news_detail_related_list_item_image">
                <img
                  src={require("../../images/body/news/news_2.png")}
                  alt="related news 1"
                />
              </div>
              <div className="news_detail_related_list_item_title">
                EU JP Workshop
              </div>
              <div className="news_detail_related_list_item_time">
                26th Feb 2020
              </div>
            </div>
            <div className="news_detail_related_list_item">
              <div className="news_detail_related_list_item_image">
                <img
                  src={require("../../images/body/news/news_3.png")}
                  alt="related news 2"
                />
              </div>
              <div className="news_detail_related_list_item_title">
                The 6th IEEE WCNC International Workshop on Smart Spectrum (IWSS
                2020).
              </div>
              <div className="news_detail_related_list_item_time">
                26th Feb 2020
              </div>
            </div>
            <div className="news_detail_related_list_item">
              <div className="news_detail_related_list_item_image">
                <img
                  src={require("../../images/body/news/news_4.png")}
                  alt="related news 3"
                />
              </div>
              <div className="news_detail_related_list_item_title">
                The 5th F2F meeting took place at Tokyo, Japan.
              </div>
              <div className="news_detail_related_list_item_time">
                26th Feb 2020
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default NewsDetail;
